@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    @apply bg-gray-900;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.001rem;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 10px;
}

.connect-animation {
    animation: connect-animation 5s ease-in-out;
    word-spacing: -12px;
}

/* animation to connect all words */
@keyframes connect-animation {
    0% {
        word-spacing: 0;
    }
    100% {
        word-spacing: -12px;
    }
}

@media (max-width: 768px) {
    newline {
        display: block;
    }
}

.section-main {
    @apply text-left snap-center flex flex-col items-start justify-center min-h-screen border-t border-gray-800 lg:p-20 md:p-10 sm:p-5 p-3;
    padding-top: 75px;
    padding-bottom: 75px;
}

.section-main-center {
    @apply text-left snap-center flex flex-col items-center justify-center min-h-screen border-t border-gray-800 lg:p-20 md:p-10 sm:p-5 p-3;
    padding-top: 75px;
    padding-bottom: 75px;
}

.text-gray-400 {
    color: rgb(156 163 175 / 400) !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.animate-fadeIn {
    animation: fadeIn 0.9s ease-in forwards;
}

.contact-grid {
    @apply p-4 flex flex-col items-center justify-center text-gray-300 pt-20 md:pt-0;
}

.contact-grid input, .contact-grid textarea {
    @apply w-full bg-gray-300 text-gray-800 mt-2 p-3 rounded-lg focus:outline-none hover:scale-[1.025] focus:scale-[1.05];
}

.contact-grid textarea {
    @apply h-32;
}

.contact-grid span {
    @apply uppercase text-sm font-bold;
}

#dev-toast-warning {
    z-index: 100;
}

.change-object {
    position: relative;
    display: inline-block;
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    vertical-align: top;
}

.change-object__object {
    position: absolute;
    opacity: 0;
    animation: change-object__object 0.5s ease-in-out forwards;
}

.change-object__object--active {
    opacity: 1;
    animation: change-object__object--active 0.5s ease-in-out forwards;
}

@keyframes change-object__object {
    0% {
        opacity: 0;
        display: none;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        display: inline;
    }
}

@keyframes change-object__object--active {
    0% {
        opacity: 1;
        display: inline;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        display: none;
        transform: translateX(-100%);
    }
}
